.keystone-label {
    transition: all 0.12s ease-in-out;
    white-space: pre-wrap;
}

.keystone-label.do-not-break {
    word-break: normal;
    white-space: nowrap;
}

.keystone-label.inline {
    display: inline-block;
}

.keystone-label.label-clickable {
    cursor: pointer;
}

.keystone-label.label-clickable:hover {
    color: var(--yellow);
}
.keystone-label.lazy {
    color: rgba(0, 0, 0, 0);
}

.keystone-label.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.keystone-label.negative {
    color: var(--ks-negative-text);
}

.keystone-label.positive {
    color: var(--ks-positive-text);
}

.keystone-label.secondary.invert {
    color: #1b2549;
}

.keystone-label.capitalize {
    text-transform: capitalize;
}

.keystone-label.shadow {
    text-shadow: 0 0 24px rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 767px) {
}