.ks-button {
    padding: 6px 12px;
    position: relative;
    transition: all 0.12s ease-in-out;
    display: flex;
    border: 1px solid transparent;
}

.ks-button:active {
    scale: 0.96;
}

.ks-button:active:disabled {
    scale: 1;
}

.ks-button.large {
    padding: 8px 24px;
}

.ks-button.small {
    padding: 4px 12px;
    height: 32px;
}

.ks-button.rounded {
    border-radius: 24px;
    padding: 4px;
}

.ks-button.brand {
    background-color: var(--ks-brand-color);
    border: 1px solid var(--ks-brand-color-dark);
    box-shadow: var(--ks-box-shadow-shallow);
}

.ks-button.transparent {
    background-color: #00000080;
    color: white;
}

.ks-button.brand.transparent {
    background-color: var(--ks-brand-color);
    box-shadow: none;
    border: none;
}

.ks-button.secondary {
    border: 1px solid var(--ks-divider);
    box-shadow: var(--ks-box-shadow-shallow);
    background-color: white;
}

.ks-button:disabled {
    background-color: var(--ks-divider-light);
    color: var(--ks-blue-dark);
    border: 1px solid transparent;
    box-shadow: none;
}

.ks-button:disabled p {
    color: var(--ks-blue-dark);
}

.ks-button:disabled .keystone-icon-wrapper {
    background-color: var(--ks-blue-dark) !important;
}

.ks-button:disabled {
    cursor: not-allowed !important;
}

.ks-button.brand-secondary {
    background-color: var(--ks-brand-color-light);
}

.ks-button.on-brand:disabled {
    color: #00000060;
    background-color: #FFFFFF40;
}

.ks-button.on-brand {
    background-color: #FFFFFF;
}

#keystone-tray .ks-button.on-brand {
    background-color: #FFFFFF40;
}

.ks-button.on-white {
    background-color: var(--ks-divider-light);
    border: 1px solid var(--ks-divider-light);
    color: var(--ks-brand-secondary);
    box-shadow: none;;
}

.ks-button.loading p, .ks-button.loading h1, .ks-button.loading h2, .ks-button.loading h3, .ks-button.loading h4, .ks-button.loading .keystone-icon-wrapper {
    opacity: 0;
}

.ks-button.loading {
    background-color: var(--ks-divider-superlight);
    border: 1px solid var(--ks-divider-transparent-light);
}

.ks-button.alternate {
    background-color: var(--ks-divider-light);
    border: 1px solid var(--ks-divider-transparent-light);
}

.ks-button.transparent.alternate {
    background-color: #00000020;
    border: 1px solid var(--ks-divider-transparent-light);
}

.ks-button.on-brand.secondary {
    background-color: #ffffff60;
    border: 1px solid transparent;
    box-shadow: none;
}

.dropdown-menu-close {
    padding: 8px !important;
}

@media (hover: hover) {
    .ks-button:hover, .ks-button.secondary:hover {
        box-shadow: var(--ks-box-shadow);
        position: relative;
        border: 1px solid var(--ks-blue-dark);
    }

    .ks-button.transparent:hover {
        background-color: black;
    }

    .ks-button.brand:hover {
        background-color: var(--ks-brand-color-hover);
    }
    
    .ks-button:disabled:hover {
        background-color: var(--ks-divider-light);
        color: var(--ks-blue-dark);
        border: 1px solid transparent;
        box-shadow: none;
        cursor: not-allowed;
    }

    .ks-button.on-brand:hover {
        background-color: #ffffffcc;
        border: 1px solid transparent;
        box-shadow: none;
    }

    .ks-button.on-white:hover {
        background-color: var(--ks-divider-light);
    }
    

    .ks-button.on-brand.secondary:hover {
        background-color: #ffffffcc;
    }

    .ks-button.yellow-bg:hover, .ks-button.white-bg:hover {
        background-color: black;
        box-shadow: none;
        border-color: black;
    }
    
    .ks-button.yellow-bg:hover p, .ks-button.white-bg:hover p, .ks-button.yellow-bg:hover h1, .ks-button.white-bg:hover h1, .ks-button.yellow-bg:hover h2, .ks-button.white-bg:hover h2, .ks-button.yellow-bg:hover h3, .ks-button.white-bg:hover h3, .ks-button.yellow-bg:hover h4, .ks-button.white-bg:hover h4 {
        color: white;
    }

    .dropdown-menu-close:hover {
        background-color: rgba(255,255,255,0.12);
        box-shadow: none;
        border-color: rgba(255,255,255,0.12);
    }
}
    
@media only screen and (max-width: 767px) {
}