.store-content {
    flex-basis: 1168px;
}

#order-request {
    overflow: hidden;
}

.pad-action-button-right {
    padding-right: 34px;
}

.featured-manufacturer-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 4% 8%;
}

#verified-mfg {
    border-radius: 6px;
    height: 96px;
    flex-basis: 320px;
    flex-grow: 1;
}

.featured-manufacturer {
    flex-basis: 160px;
    height: 96px;
    flex-grow: 1;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 767px) {
    .featured-manufacturer {
        flex-basis: 88px;
        height: 72px;
    }

    #verified-mfg {
        height: 72px;
    }

    #order-request {
        width: 100%;
    }

    .pad-action-button-right {
        padding-right: 28px;
    }

    .mobile-footer-pad {
        padding-bottom: var(--footer-height);
    }

    .mobile-bump-pad {
        padding-bottom: calc(var(--footer-height) + 64px);
    }
}