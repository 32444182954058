.tile {
    border: 1px solid var(--ks-divider-transparent-light);
    border-radius: 6px;
    box-shadow: var(--ks-box-shadow-shallow);
    background: white;
    transition: all 0.13s ease-in-out;
}

.tile.fade {
    opacity: 0.6;
}

.tile.row-style {
    border: none;
    border-bottom: 1px solid var(--ks-divider);
    box-shadow: none;
    background: none;
    border-radius: 0;
}

.tile.row-style:last-child {
    border-bottom: none;
}

.tile.row-style.clickable:hover {
    background: var(--ks-divider-light);
}

.tile.row-style .image-wrapper:hover {
    border-color: var(--ks-divider-dark);
}

@media only screen and (max-width: 767px) {
}