
.product-details-header {
    flex-basis: 240px;
    flex-grow: 4;
}

.stock-details-row-header {
    flex-basis: 360px;
    flex-grow: 6;
}

.stock-details-container {
    flex-basis: 240px;
    flex-grow: 4;
}

.stock-rows-container {
    flex-basis: 360px;
    flex-grow: 6;
}

.product-details-container {
    flex-basis: 100%;
}

.multi-line-ellipsis-3-lines {
    display: flex;
    display: -webkit-box;
    max-height: 3.6em;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.floater {
    position: absolute;
    top: 0px;
    right: 0px;
}

.cart-product-description {
    flex-grow: 4;
    flex-basis: 25;
}