#store-sidebar {
 flex-basis: 320px;
 flex-shrink: 1;
 flex-grow: 0;
 min-width: 220px;
}

#category-select {
    display: none;
}

#categories-wrapper {

}

@media only screen and (max-width: 767px) {
    #store-sidebar {
       width: 100%;
       min-width: 100%;
       position: fixed;
       z-index: 3;
       bottom: 0px;
       left: 0px;
       right:0;
       padding: 0;
       row-gap: 0;
       border-radius: 0px;
    }

    #category-select {
        display: flex;
        background-color: var(--black);
        color: white;
        border-radius: 0px;
    }

    #category-select .keystone-action.menu-shown .white, .keystone-action .white:hover {
        color: black;
        background-color: var(--yellow);
    }

    #categories-wrapper {
        display: none;
        max-height: 230px;
        overflow: scroll;
        border-radius: 0px;
    }

    #categories-wrapper.menu-visible {
        display: flex;
        background-color: var(--blue);
        box-shadow: none;
    }

    #footer-links {
        display: none;
    }
}