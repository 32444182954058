.keystone-input {
    border-radius: 4px;
    background-color: white;
    box-shadow: var(--ks-box-shadow-shallow);
    border: 1px solid var(--ks-divider);
}

.keystone-input:focus {
    background-color: white;
    border-radius: 4px;
    border: 1px solid var(--ks-blue-dark);
}

.keystone-input.large {
    padding: 9px 12px;
}

.keystone-input.small {
    padding: 8px 12px;
}

.keystone-input.on-brand {}

.keystone-input.on-white, .keystone-select.on-white {
    background-color: var(--ks-divider-light);
    box-shadow: none;
    border: none;
}

.keystone-input.on-white.warning {
    background-color: var(--ks-brand-color-light);
}

.keystone-input.on-white.success {
    background-color: var(--ks-positive-background);
}

.keystone-input::-webkit-outer-spin-button,
.keystone-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* style placeholder color */
.keystone-input::placeholder {
    color: var(--ks-blue-dark);
}


.keystone-input.on-brand {
    color: #ffffff;
}

.keystone-input.on-brand::placeholder {
    color: #ffffff90;
}

.keystone-input.align-right {
    text-align: right;
}

.keystone-input.error {
    border-color: var(--red);
}


@media only screen and (max-width: 767px) {
}