.keystone-action {
    border-radius: 4px;
    transition: all 0.12s ease-in-out;
    scale: 1;
}

#category-navigate.keystone-action {
    background: var(--ks-divider-light);
}

.keystone-action.active {
    background-color: white;
}

.keystone-action:active {
    scale: 0.96;
}

.keystone-action p, .keystone-action h1, .keystone-action h2, .keystone-action h3, .keystone-action h4 {
    display: flex;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.14s ease-in-out;
}

.keystone-action.breadcrumb p, .keystone-action.breadcrumb h1, .keystone-action.breadcrumb h2, .keystone-action.breadcrumb h3, .keystone-action.breadcrumb h4 {
    padding: 0;
}

.keystone-action p:hover, .keystone-action h1:hover, .keystone-action h2:hover, .keystone-action h3:hover, .keystone-action h4:hover {

}

.keystone-action:hover {
    background-color: var(--ks-divider-light);
}

.keystone-action.negative {
    color: var(--ks-negative-text);
}

.keystone-action.positive {
    color: var(--ks-positive-text);
}

.keystone-action.breadcrumb {
    background-color: transparent;
}

.keystone-action.breadcrumb p, .keystone-action.breadcrumb h1, .keystone-action.breadcrumb h2, .keystone-action.breadcrumb h3, .keystone-action.breadcrumb h4 {
    color: var(--ks-blue-dark);
}
.keystone-action.breadcrumb:hover {
    background-color: transparent;
}
.keystone-action.breadcrumb:hover p, .keystone-action.breadcrumb:hover h1, .keystone-action.breadcrumb:hover h2, .keystone-action.breadcrumb:hover h3, .keystone-action.breadcrumb:hover h4 {
    color: black;
}

.keystone-action.breadcrumb.active p, .keystone-action.breadcrumb.active h1, .keystone-action.breadcrumb.active h2, .keystone-action.breadcrumb.active h3, .keystone-action.breadcrumb.active h4 {
    color: black;
}

.keystone-action.on-white.breadcrumb {
    background-color: white;
}

.keystone-action.on-white.breadcrumb:hover {
    background-color: var(--ks-divider-superlight);
}


.keystone-action.on-white.breadcrumb.active {
    background-color: var(--ks-divider);
    scale: 0.96;
}

.keystone-action.disabled, .keystone-action.disabled:hover .keystone-action.disabled p:hover, .keystone-action.disabled:hover .keystone-action.disabled h1:hover, .keystone-action.disabled:hover .keystone-action.disabled h2:hover, .keystone-action.disabled:hover .keystone-action.disabled h3:hover, .keystone-action.disabled:hover .keystone-action.disabled h4:hover {
    cursor: not-allowed;
    color: var(--ks-divider-dark);
    background-color: white;
}

.keystone-action.underline h3 {
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
}

.keystone-action.underline:hover h3 {
    color: var(--ks-dark-blue);
}

.keystone-action.underline:hover {
    background-color: transparent;
}

@media only screen and (max-width: 767px) {
}