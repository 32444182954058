.stock-row-v2 {

}
.stock-row-v2.card-style {
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-between;
}


.available-quantity-container {
    flex-basis: 124px;
    flex-shrink: 1;
    flex-direction: column;
}
.card-style .available-quantity-container {
    flex-basis: 45%;
    flex-grow: 1;
}
.card-style .available-quantity-country-subtitle {
    display: none;
}


.available-quantity-header-title {
    display: none;
}
.card-style .available-quantity-header-title {
    display: flex;
}


.approved-price-container {
    flex-basis: 148px;
    flex-grow: 1;
    flex-direction: column;
}
.card-style .approved-price-container {
    flex-basis: 45%;
    flex-grow: 1;
}


.offer-quantity-container {
    flex-basis: 100px;
    align-items: flex-end;
    flex-direction: column;
}
.card-style .offer-quantity-container {
    flex-basis: 45%;
    flex-grow: 1;
    align-items: stretch;
}
.card-style .offer-quantity-add-all {
    align-self: center;
}


.offer-quantity-input-header {
    display: none;
}
.card-style .offer-quantity-input-header {
    display: flex;
    align-self: flex-start;
}


.offer-price-container {
    flex-basis: 124px;
    align-items: flex-end;
    flex-direction: column;
    flex-grow: 0;
}
.card-style .offer-price-container {
    flex-basis: 45%;
    flex-grow: 1;
    align-items: stretch;
}


.offer-price-input-header {
    display: none;
}
.card-style .offer-price-input-header {
    display: flex;
    align-self: flex-start;
}
.card-style .offer-price-unit-subtitle {
    align-self: flex-end;
}


.ship-from-country-text {
    display: none;
}
.card-style .ship-from-country-text {
    display: flex;
}


.ship-from-location-container {
    flex-direction: column;
}
.card-style .ship-from-location-container {
    flex-basis: 45%;
    flex-grow: 1;
}


.lead-time-container {
    flex-direction: column;
    display: none;
}
.card-style .lead-time-container {
    display: flex;
    flex-basis: 45%;
    flex-grow: 1;
}


.approved-price-subtitle-container {
    flex-direction: row;
    align-items: center;
}
.card-style .approved-price-subtitle-container {
    display: none;
}


.approved-price-header-title {
    display: none;
}
.card-style .approved-price-header-title {
    display: flex;
}


.country-flag-img {
    width: 24px;
    height: auto;
    object-fit: contain;
}
.card-style .country-flag-img {
    width: 16px;
}
