.keystone-chip-rounded {
   color: white;
   display: flex;
   border-radius: 4px;
   align-items: center;
   height: fit-content;
   width: fit-content;
}

.keystone-chip-rounded.icon {
   padding-left: 2px;
}

.keystone-chip-rounded.secondary {
   background-color: var(--ks-divider);
   color: var(--ks-blue-dark);
}

.keystone-chip-rounded p, .keystone-chip-rounded h1, .keystone-chip-rounded h2, .keystone-chip-rounded h3, .keystone-chip-rounded h4 {
   padding: 4px 6px;
}

.keystone-chip-rounded.icon p, .keystone-chip-rounded.icon h1, .keystone-chip-rounded.icon h2, .keystone-chip-rounded.icon h3, .keystone-chip-rounded.icon h4 {
   padding: 4px 6px;
   padding-left: 2px;
   width: fit-content;
}

.keystone-chip-rounded.positive {
   color: var(--ks-positive-text);
   background-color: var(--ks-positive-background);
}

.keystone-chip-rounded.warning {
   color: var(--warning-text);
   background-color: var(--warning-background);
}

.keystone-chip-rounded.brand {
   color: var(--ks-positive-text);
   background-color: var(--ks-brand-color);
}

.keystone-chip-rounded.small p, .keystone-chip-rounded.small h1, .keystone-chip-rounded.small h2, .keystone-chip-rounded.small h3, .keystone-chip-rounded.small h4 {
   padding: 1px 4px 2px 4px;
}

.keystone-chip-rounded.negative {
   color: var(--ks-negative-text);
   background-color: var(--ks-negative-background);
}
.keystone-chip-rounded.width-100 {
   width: 100%;
   justify-content: center;
}
@media only screen and (max-width: 767px) {
}