.keystone-icon-wrapper {
   mask-size: cover;
   -webkit-mask-size: cover;
   mask-repeat: no-repeat;
   -webkit-mask-repeat: no-repeat;
   mask-position: center;
   -webkit-mask-position: center;
   display: inline-block;
   transition: all 0.24s ease-in-out;
}

.keystone-icon-wrapper.info-circle {
   mask-image: url('../../../media/info_circle_outline_24.png');
   --webkit-mask-image: url('../../../media/info_circle_outline_24.png');
}

.keystone-icon-wrapper.info-circle:hover {
   mask-image: url('../../../media/info_circle_fill_24.png');
   --webkit-mask-image: url('../../../media/info_circle_fill_24.png');
}

.keystone-icon-wrapper.checkmark-circle {
   mask-image: url('../../../media/checkmark_circle_outline_24.png');
   --webkit-mask-image: url('../../../media/checkmark_circle_outline_24.png');
}

.keystone-icon-wrapper.checkmark-circle:hover {
   mask-image: url('../../../media/checkmark_circle_fill_24.png');
   --webkit-mask-image: url('../../../media/checkmark_circle_fill_24.png');
}

.tag-wrapper {
   background-color: var(--ks-positive-background);
   flex-basis: fit-content;
   display: flex;
   align-self: flex-start;
}

.tag-wrapper:hover {
   background-color: var(--ks-positive-text);
}

.tag-wrapper:hover .keystone-icon-wrapper.tag {
   background-color: var(--ks-positive-background) !important;
}

.keystone-icon-wrapper.tag {
   mask-image: url('../../../media/tag_fill_24.png');
   --webkit-mask-image: url('../../../media/tag_fill_24.png');
}

.keystone-icon-wrapper.clickable:hover {
   background-color: black !important;
}

@media only screen and (max-width: 767px) {
}