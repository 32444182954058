a.invisible {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    pointer-events: none;
}

.link-button {
    text-decoration: none;
    transition: all 0.13s ease-in-out;
    padding: 4px 6px;
}

.link-button.icon {
    padding: 6px;
}

.link-button:hover {
    color: black;
    background-color: var(--ks-divider);
}

.link-button:hover .keystone-icon-wrapper {
    background-color: black !important;
}