.footer-container {
    flex-grow: 1;
}

.grouped-info {
    flex-grow: 1;
    align-items: stretch;
}

@media only screen and (max-width: 767px) {
}